<template>
  <Content>
    <template #contentTitle>{{ $t('menu.storage_label_change_list') }}</template>
    <template #contentBody="wrap">
      <a-row :gutter="[16, 16]" type="flex" justify="space-between">
        <a-col>
          <a-row :gutter="[16, 16]">
            <a-col>
              <a-input
                style="width: 250px;"
                v-model:value="searchState.searchKey"
                :placeholder="$t('warehouse.label_task_name')+'/'+$t('warehouse.tag_task_number')"
                :title="$t('warehouse.label_task_name')+'/'+$t('warehouse.tag_task_number')"
                allow-clear
              ></a-input>
            </a-col>
              <a-col>
               <a-select
                style="width: 250px"
                v-model:value="searchState.boxNoes"
                mode="tags"
                :maxTagCount="1"
                :maxTagTextLength="18"
                :token-separators="[',', '，', ' ', '\t', '\r', '\n']"
                :placeholder="$t('warehouse.please_enter_the_container_number')"
                allowClear
                @change="handleSelectValueChange('boxNoes')"
              ></a-select>
            </a-col>
            <a-col>
              <a-select
                style="width: 250px;"
                v-model:value="searchState.warehouseId"
                :placeholder="$t('logistics.warehouse_code')"
                allow-clear
              >
                <a-select-option
                  v-for="(item, index) in warehouseList"
                  :key="index"
                  :value="item.id"
                  :title="item.warehouseNo + '('+ item.warehouseName +')'"
                >{{ item.warehouseNo + '('+ item.warehouseName +')' }}</a-select-option>
              </a-select>
            </a-col>
            <a-col>
              <a-select
                style="width: 250px;"
                v-model:value="searchState.status"
                :placeholder="$t('warehouse.task_status')"
                allow-clear
              >
                <a-select-option
                  v-for="(item, index) in labelTaskStatusEnum"
                  :key="index"
                  :value="item"
                  :title="$t($enumLangkey('labelTaskStatus', item))"
                >{{ $t($enumLangkey('labelTaskStatus', item)) }}</a-select-option>
              </a-select>
            </a-col>
            <a-col>
              <a-button type="primary" :loading="tableData.loading" @click="handleSearch">{{ $t('common.search') }}</a-button>
            </a-col>
          </a-row>
        </a-col>
        <a-col>
          <a-button type="ghost">
            <router-link to="/storage/labelchange/create">
              {{ $t('common.create') }}
            </router-link>
          </a-button>
        </a-col>
      </a-row>
      <a-table
        class="mt-3"
        size="small"
        :columns="columns"
        :data-source="tableData.tableList"
        :scroll="{ x: true, y: wrap.contentHeight - 130 }"
        :pagination="false"
        :row-key="(record) => record.taskId"
        :loading="tableData.loading"
      >
        <template #labelTaskTitle>
          <div>{{ $t('warehouse.label_task_name') }}</div>
          <div>{{ $t('warehouse.tag_task_number') }}</div>
        </template>
        <template #shipmentInformationTitle>
          <div>{{ $t('warehouse.shipment_name') }}</div>
          <div>{{ $t('warehouse.se_shipment_number') }}</div>
        </template>
        <template #dateTitle>
          <div>{{ $t('warehouse.task_time') }}</div>
          <div>{{ $t('warehouse.update_time') }}</div>
        </template>
        <template #labelTask="{ record }">
          <div>{{ record.taskName }}</div>
          <div>{{ record.taskNo }}</div>
        </template>
        <template #boxCount="{ record }">
          {{ record.boxCount || '-' }}
        </template>
        <template #skuCount="{ record }">
          {{ record.skuCount || '-' }}
        </template>
        <template #productCount="{ record }">
          {{ record.productCount || '-' }}
        </template>
        <template #actualProductCount="{ record }">
          {{ record.actualProductCount || '-' }}
        </template>
        <template #taskStatus="{ record }">
          {{ $t($enumLangkey('labelTaskStatus', record.taskStatus)) }}
        </template>
        <template #dateCustomer="{ record }">
          <div>{{ $filters.utcToCurrentTime(record.creationTime) }}</div>
          <div>{{ $filters.utcToCurrentTime(record.lastModificationTime) }}</div>
        </template>
        <template #operation="{ record }">
          <a-dropdown-button @click="handleButtonClick(record)">
            <span v-if="record.taskStatus === labelTaskStatusEnum.planned">{{ $t('common.edit') }}</span>
            <span v-else>{{ $t('common.details') }}</span>
            <template #overlay>
              <a-menu @click="handleMenuClick">
                <a-menu-item key="1" :record="record" :disabled="!record.cancelled">{{ $t('common.cancel') }}</a-menu-item>
              </a-menu>
            </template>
          </a-dropdown-button>
        </template>
      </a-table>
    </template>
    <template #contentFooter>
      <CPager class="text-center" :page-data="tableData.pageData" @handlePage="handlePage"></CPager>
    </template>
  </Content>
</template>

<script>
import { defineComponent, onActivated, onMounted, reactive, toRefs } from 'vue';
import { Button, Col, Dropdown, Input, Menu, Modal, Row, Select, Table, message } from 'ant-design-vue';
import Content from '@/views/components/Content.vue';
import CPager from '@/views/components/CPager.vue';
import { useI18n } from "vue-i18n/index";
import { useRouter } from 'vue-router';
import { labelTaskStatus as labelTaskStatusEnum, warehouseType as warehouseTypeEnum } from '@/enum/enum.json';
import { getWarehouses } from '@/api/modules/common/index.js';
import { getPagedList, cancelStorageUserLabelChange } from '@/api/modules/storage/labelChange.js';

export default defineComponent({
  name: 'storage_label_change_list',
  components: {
    ARow: Row,
    ACol: Col,
    AInput: Input,
    ASelect: Select,
    ASelectOption: Select.Option,
    AButton: Button,
    ATable: Table,
    ADropdownButton: Dropdown.Button,
    AMenu: Menu,
    AMenuItem: Menu.Item,
    Content,
    CPager,
  },
  setup () {
    const vueI18n = useI18n({ useScope: "global" });
    const router = useRouter();

    const state = reactive({
      searchState: {
        searchKey: null,
        warehouseId: null,
        status: null,
        productId: null,
        boxNoes: [],
      },
      searchStateCache: {},
      warehouseLoading: false,
      warehouseList: [],
      tableData: {
        tableList: [],
        loading: false,
        pageData: {
          currentIndex: 1,
          skipCount: 0,
          totalCount: 0,
          maxResultCount: 10,
        },
      },
    });

    const columns = [
      {
        dataIndex: "warehouseNo",
        width: 120,
        title: () => vueI18n.t('warehouse.warehouse_code'),
      },
      {
        width: 200,
        slots: {
          title: "labelTaskTitle",
          customRender: "labelTask",
        }
      },
      {
        width: 100,
        title: () => vueI18n.t('warehouse.number_of_task_boxes'),
        slots: {
          customRender: "boxCount",
        }
      },
      {
        width: 100,
        title: () => vueI18n.t('warehouse.sku_number'),
        slots: {
          customRender: "skuCount",
        }
      },
      {
        width: 100,
        title: () => vueI18n.t('warehouse.product_quantity'),
        slots: {
          customRender: "productCount",
        }
      },
      {
        width: 100,
        title: () => vueI18n.t('warehouse.quantity_of_finished_products'),
        slots: {
          customRender: "actualProductCount",
        }
      },
      {
        width: 120,
        title: () => vueI18n.t('warehouse.task_status'),
        slots: {
          customRender: "taskStatus",
        }
      },
      {
        width: 120,
        slots: {
          title: "dateTitle",
          customRender: "dateCustomer",
        }
      },
      {
        width: 150,
        title: () => vueI18n.t('common.operation'),
        align: 'center',
        fixed: 'right',
        slots: {
          customRender: "operation",
        }
      },
    ]

    const handleButtonClick = (record) => {
      if (record.taskStatus === labelTaskStatusEnum.planned) {
        switch (record.operateStep) {
          case 1:
            router.push('/storage/labelchange/uploadtags/' + record.taskId);
            break;
          case 2:
            router.push('/storage/labelchange/confirm/' + record.taskId);
            break;
          default:
            break;
        }
      } else {
        router.push('/storage/labelchange/' + record.taskId);
      }
    }

    const handleMenuClick = (e) => {
      let { key, item } = e;
      let { record } = item;
      switch (key) {
        case '1':
          // 取消
          handleCancel(record.taskId);
          break;
        default:
          break;
      }
    }

    const handleCancel = (taskId) => {
      Modal.confirm({
        title: vueI18n.t("common.operation"),
        content: vueI18n.t("common.are_you_sure_cancel"),
        okText: vueI18n.t("common.confirm"),
        cancelText: vueI18n.t("common.cancel"),
        onOk: () => {
          return cancelStorageUserLabelChange({ taskId }).then(() => {
            message.success(vueI18n.t("common.successfully_canceled"));
            getPageList();
          });
        },
      });
    }

    const getPageList = () => {
      state.tableData.loading = true;
      const data = Object.assign(
        {},
        state.tableData.pageData,
        state.searchStateCache
      );

      getPagedList(data)
        .then((res) => {
          state.tableData.loading = false;
          let { items = [], totalCount = 0 } = res.result;
          state.tableData.pageData.totalCount = parseInt(totalCount);
          state.tableData.tableList = items;
        })
        .catch(() => {
          state.tableData.loading = false;
        });
    };

    const handleInitPage = () => {
      state.tableData.pageData.skipCount = 0
      state.tableData.pageData.currentIndex = 1
      getPageList();
    };

    const handleSearch = () => {
      Object.assign(state.searchStateCache, state.searchState);
      handleInitPage();
    };

    const handlePage = (pageData) => {
      state.tableData.pageData.skipCount = pageData.skipCount;
      state.tableData.pageData.maxResultCount = pageData.maxResultCount;
      getPageList();
    };
    
    const getWarehouseList = () => {
      state.warehouseLoading = true;
      const data = {
        warehouseType: warehouseTypeEnum.storageWarehouse,
        isActive: true,
      }
      getWarehouses(data).then(({ result }) => {
        if (Array.isArray(result)) {
          state.warehouseList = result;
        } else {
          state.warehouseList = [];
        }
      }).catch(() => {}).finally(() => {
        state.warehouseLoading = false;
      })
    }

    const handleSelectValueChange = (key) => {
      let value = state.searchState[key];
      if (Array.isArray(value)) {
        state.searchState[key] = value.map(item => {
          item = item.trim();
          return item;
        });
      }
    }

    onActivated(handleSearch);

    onMounted(getWarehouseList);

    return {
      ...toRefs(state),
      labelTaskStatusEnum,
      columns,
      handleButtonClick,
      handleMenuClick,
      handleSearch,
      handlePage,
      handleSelectValueChange
    }
  }
})
</script>

<style scoped></style>